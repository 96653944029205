/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

import 'stylesheets/application';

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


import '@fortawesome/fontawesome-free/js/solid'
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()

// import 'bootstrap/dist/js/bootstrap'
import 'bootstrap'

global.Bloodhound = require("typeahead.js/dist/typeahead.bundle")
import 'expose-loader?exposes[]=Handlebars!handlebars'

// global.tempusDominus = require('@eonasdan/tempus-dominus')
import * as tempusDominus from "@eonasdan/tempus-dominus"
global.tempusDominus = tempusDominus


require('src/bootstrap-filestyle')
console.log('required sources')

// Turbolinks.dispatch("turbolinks:load");

// datatables
require( 'datatables.net-bs5' )
require( 'datatables.net-fixedcolumns-bs5' )
require( 'datatables.net-fixedheader-bs5' )
require( 'datatables.net-responsive-bs5' )
require( 'datatables.net-select-bs5' )

// stimulus.js
import "controllers"

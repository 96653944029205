import { Controller } from "stimulus"
// import tempusDominus from '@eonasdan/tempus-dominus'
import dayjs from "dayjs"

export default class extends Controller {
  static targets = [ "startDate", "endDate", "weeks" ]

  connect() {
    this.isInitializing = true
    console.log("date range controller connected")
    
    const controllerName = `stimulus_${this.identifier}`
    this.element[controllerName] = this
    this.startDateTD = null
    this.endDateTD = null
    
    if (this.hasStartDateTarget) {
      console.log(`start date target: ${this.startDateTarget} ${this.startDateTarget.value}`)
      // const startDay = dayjs(this.startDateTarget.value, ["MM/DD/YYYY", "MM-DD-YYYY"])
      // console.log(`start date dayjs: ${startDay.format()}`)
      this.startDateTarget[controllerName] = this
      this.createTempusDominusPicker(this.startDateTarget)
    }
    if (this.hasEndDateTarget) {
      this.createTempusDominusPicker(this.endDateTarget)
      console.log(`end date target: ${this.endDateTarget.value}`)
    }
    console.log(`weeks target: ${this.weeksTarget.value}`)
    this.isInitializing = false
  }
  
  
  calculate(event) {
    if (this.isInitializing) { return }
    console.log(`calculating`)
    console.log(event)
    var changedElement = event.target
    if (changedElement.nodeName.toLowerCase() != 'input') {
      changedElement = event.target.getElementsByTagName('input')[0];
    }
    if (changedElement == null) {
      return
    }
    if (changedElement == this.startDateTarget) {
      let newStartDate = event.detail ? dayjs(event.detail.date) : dayjs(changedElement.value, ["MM/DD/YYYY", "MM-DD-YYYY"])
      let weeks = this.hasWeeksTarget ? parseInt(this.weeksTarget.value) : 0
      console.log(`calculate with changed start date ${newStartDate.format()}`)
      if (weeks > 0) {
        let newEndDate = newStartDate.add(weeks - 1, 'week').day(6)
        this.setTempusDominusPickerDate(this.endDateTarget, newEndDate.toDate())
        return;
      }
      let endDate = dayjs(this.endDateTarget.value, ["MM/DD/YYYY", "MM-DD-YYYY"])
      if (endDate != null && this.hasWeeksTarget) {
        // calculate weeks and set it
      }
    }
    if (changedElement == this.endDateTarget && this.hasWeeksTarget) {
      let startDate = dayjs(this.startDateTarget.value, ["MM/DD/YYYY", "MM-DD-YYYY"])
      let newEndDate = event.detail ? dayjs(event.detail.date) : dayjs(changedElement.value, ["MM/DD/YYYY", "MM-DD-YYYY"])
      let weeksDiff = newEndDate.diff(startDate, 'week', true)
      let startDay = startDate.day()
      let endDay = newEndDate.day()
      let weekNum = startDay > endDay ? Math.ceil(weeksDiff) : Math.floor(weeksDiff)
      console.log(`calculate with changed end date ${newEndDate.format()} with weeks ${weekNum} (${weeksDiff}) startDay ${startDay} endDay ${endDay}`)
      this.weeksTarget.value = weekNum + 1 // count weeks based on week number
    }
    if (changedElement == this.weeksTarget && parseInt(this.weeksTarget.value) > 0) {
      console.log("calculate with changed weeks")
      let startDate = dayjs(this.startDateTarget.value, ["MM/DD/YYYY", "MM-DD-YYYY"])
      let newEndDate = startDate.add(parseInt(this.weeksTarget.value) - 1, 'week').day(6)
      console.log(`calculated new end date ${newEndDate} from ${startDate}`)
      this.setTempusDominusPickerDate(this.endDateTarget, newEndDate.toDate())
    }
    
  }
  
  
  
  // set up TempusDominus picker
  createTempusDominusPicker(target) {
    this.unsubscribeTempusDominusChange(target)
    
    let tempusDominusOptions = {
      allowInputToggle: true,
      display: {
         viewMode: 'months',
         components: { decades: true, year: true, month: true, date: true, hours: false, minutes: false, seconds: false, }
       }
    }
    target['tdPicker'] = new tempusDominus.TempusDominus(target.parentElement, tempusDominusOptions);
    this.subscribeTempusDominusChange(target)
  }
  
  subscribeTempusDominusChange(target) {
    target['tdPickerChangeListener'] = target['tdPicker'].subscribe(tempusDominus.Namespace.events.change, (event) => {
      event.target = target
      this.calculate(event)
    })
  }
  
  unsubscribeTempusDominusChange(target) {
    if (target['tdPickerChangeListener']) {
      target['tdPickerChangeListener'].unsubscribe()
    }
  }
  
  setTempusDominusPickerDate(target, newDate) {
    this.unsubscribeTempusDominusChange(target)
    // console.log(target['tdPicker'])
    const parsedDate = target['tdPicker'].dates.parseInput(newDate);
    target['tdPicker'].dates.setValue(parsedDate)
    this.subscribeTempusDominusChange(target)
  }
  
}

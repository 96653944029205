import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "facilityID" ]
  //
  // connect() {
  //   // this.load()
  //   console.log(`assignment-facility controller connected; facility id ${this.facilityIDTarget.value}`)
  // }
  //
  // update() {
  //   console.log(`facilityID changed to ${this.facilityIDTarget.value}`)
  // }
  //
  // load() {
  //   fetch(this.data.get("url"))
  //     .then(response => response.text())
  //     .then(html => {
  //       this.element.innerHTML = html
  //     })
  // }
}

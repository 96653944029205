import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "table", "row", "modal" ]

  connect() {
    // console.log("table controller connected");
    
    const controllerName = `stimulus_${this.identifier}`;
    this.element[controllerName] = this;
    if (this.hasTableTarget) {
      this.tableTarget[controllerName] = this;
    }
    this.dt = null; // datatables instance can be plugged in
    this.modalID = `${controllerName}_modal`;
    
    if (this.data.get('lazyload') !== null) {
      this.lazyload = new LazyLoad({ elements_selector: `.${this.data.get('lazyload')}` });
    }
    
    const event = new CustomEvent("table-controller-connected", {detail: {element: this.element, table: this.tableTarget}});
    // fire connected event later so controller can finish connecting events
    window.requestAnimationFrame(function() {
      window.dispatchEvent(event);
    });
  }
  
  // actions
  setupDataTable(event) {
    if (this.dt == null) {
      // DataTables is a jquery function
      this.dt = $(this.tableTarget).DataTable(event.detail.dt_options);
      // console.log("DataTable set up")
      if (this.lazyload) {
        this.lazyload.update();
      }
    }
  }
  
  replaceAllRows(event) {
    if (this.dt == null) { return; }
    this.dt.clear();
    this.dt.rows.add(event.detail.rows).draw();
    if (this.lazyload) {
      this.lazyload.update();
    }
  }
  
  click(event) {
    // check if selecting text
    if (window.getSelection().toString().length > 0) {
      return;
    }
    
    // don't do anything if a link in the row was clicked
    if (event.target.nodeName.toUpperCase() == 'A') {
      return;
    }
    
    // add checks for forms/buttons?
    
    // console.log("stimulus table controller click event")
    // console.log(event);
    
    // get the row
    const row = this.rowForEvent(event);
    if (row == null) { 
      return;
    }
    const rowData = this.dt.row(row).data();
    // console.log("row for event");
    // console.log(row);
    // console.log(rowData);
    
    
    // remove modal if it exists
    if (this.hasModalTarget) {
      // console.log("removing modal");
      this.modalTarget.remove();
    }
    
    // get URL and fetch it
    const url = rowData.edit_url ?? row.dataset.editUrl;
    // console.log(`found edit url: ${url}`);
    if (url == null) {return; }
    
    fetch(url).then(response => response.json())
      .then(data => {
        // json response should have message, optionally title, size
        this.element.append(this.buildModal(data));
        // open the modal
        $(`#${this.modalID}`).modal();
      })
  }
  
  // events
  onFormSuccess(event) {
    console.log("form success");
    const [data, status, xhr] = event.detail;
    // get row by ID
    if (this.hasRowTarget) {
      const row = this.rowTargets.find(row => row.dataset.id == `${data.id}`);
      if (row) {
        row.innerHTML = data.row;
      } else {
        // didn't find, insert
        alert("need to implement HTML row insert");
      }
    }
    else if (this.dt && data.id !== null) {
      this.dt.rows().every( function () {
        const rowData = this.data();
        if (rowData.id == data.id) {
          console.log(`updating row data for id ${data.id}`);
          this.data(data).invalidate();
        }
      });
      
      // const rowIdx = this.dt.rows().data().findIndex((rowData) => rowData.id == data.id);
      // console.log(`found row index for update: ${rowIdx}`);
      // const row = this.dt.table.row(rowIdx);
      // row.data(data).invalidate();
    }
    
    if (this.dt) {
      this.dt.draw();
    }
    if (this.lazyload) {
      this.lazyload.update();
    }
  }
  
  onFormComplete(event) {
    $(`#${this.modalID}`).modal('hide');
    this.modalTarget.remove();
  }
  
  
  // utility
  buildModal(options) {
    const sizeClass = options.size ? `modal-${options.size}` : '';
    let modalText = `
      <div id="${this.modalID}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" data-bs-target='table.modal'>
        <div class="modal-dialog ${sizeClass}" role="document">
          <div class="modal-content">
    `;
    if (options.title) {
      modalText += `
            <div class="modal-header">
              <h5 class="modal-title">${options.title}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
      `;
    }
    modalText += `
            <div class="modal-body">
            ${options.body}
            </div>
          </div>
        </div>
      </div>
    `;
    
    const modal = this.htmlToElement(modalText);
    // add actions to form
    const form = modal.querySelector('form');
    if (form) {
      form.dataset.action = "ajax:success->table#onFormSuccess ajax:complete->table#onFormComplete";
    }
    return modal;
  }
  
  
  htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }
  
  
  rowForEvent(event) {
    var row = null;
    if (event.target.nodeName.toUpperCase() == 'TR') {
      row = event.target;
    } else if (event.target.nodeName.toUpperCase() == 'TD') {
      row = event.target.parentNode;
    } else {
      row = event.target.closest('tr');
    }
    if (row == null) { return null; }
    return (row.parentNode.nodeName.toUpperCase() == 'TBODY') ? row : null;
  }
  
}
